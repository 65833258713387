import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function LetsDiskoPage() {
  return (
    <TicketshopPage
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="c9b328b7-d5d4-49ca-bf12-ed2de551af5f" data-fixr-theme="light" data-name-filter="letsdisko"></script>`}
      imageSrc="/event-logo-letsdisko.png"
      imageStyle={{ maxWidth: 574 }}
      title="LETSDISCO"
    />
  );
}
